<template>
  <div style="display: grid; place-items: center; height: 100%">
    <v-card v-if="state === 1 && 0" style="width: 500px; margin-top: -64px">
      <v-card-title class="headline text-center"
        >הפעל את מערכת אבוקה לישוב</v-card-title
      >
      <v-card-text>
        <v-select
          v-model="selectedMessage"
          :items="messages"
          label="בחר הודעה לשליחה"
          outlined
          item-value="message"
          required
        ></v-select>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn :disabled="!selectedMessage" color="primary" @click="sendMessage"
          >הפעל</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-else class="avuka-card">
      <div class="d-flex justify-center">
        <span v-if="noEvent" class="my-4">אין ארוע פתוח כעת באבוקה</span>
      </div>
      <div v-if="!loading" class="d-flex justify-center my-4 align-center">
        <span class="me-4" v-if="!noEvent"
          >עדכון אחרון: {{ lastUpdate && lastUpdate.format('HH:mm:ss') }}</span
        >
        <v-btn @click="updateData(true)">{{
          noEvent ? 'בדוק על ארוע חדש' : 'עדכן'
        }}</v-btn>
        <span class="ms-4" v-if="!noEvent"
          >עדכון הבא:
          {{
            lastUpdate &&
            lastUpdate.add(updateEvery, 'minutes').format('HH:mm:ss')
          }}</span
        >
      </div>
      <div v-else class="d-flex align-center flex-column">
        <span class="my-4">טוען נתונים...</span>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>

      <div v-if="!noEvent" class="d-flex px-4" style="gap: 12px">
        <v-select
          :items="distinctCities"
          v-model="filterCity"
          label="סינון לפי ישוב"
        ></v-select>
        <v-select
          :items="distinctQuarters"
          v-model="filterQuarter"
          label="סינון לפי אשכול"
        ></v-select>
        <v-select
          :items="distinctResponseStatuses"
          v-model="filterResponseStatus"
          label="סינון לפי סטטוס"
        ></v-select>
      </div>
      <v-text-field
        v-if="!noEvent"
        class="mx-4"
        v-model="search"
        label="חיפוש"
        dense
        clearable
      ></v-text-field>
      <v-data-table
        v-if="!noEvent"
        :headers="familyHeaders"
        :items="filteredItems"
        sort-by="Created"
        :sort-desc="true"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        item-key="key"
        :expanded-items="filteredItems"
        :items-per-page="50"
        :footer-props="{ 'items-per-page-options': [100, 10, 50, -1] }"
        :mobile-breakpoint="100"
        :item-class="setRowHomeClass"
      >
        <template v-slot:[`item.manualResponse`]="{ item }">
          <v-checkbox
            @change="manualResponseHandler(item)"
            v-model="item.manualResponse"
            color="primary"
            hide-details
            class="mt-0 pt-0"
          ></v-checkbox>
        </template>
        <template v-slot:[`item.residentsStatus`]="{ item }">
          <div class="d-flex flex-wrap" style="direction: ltr">
            <span
              v-for="(statusNum, index) in generateHomeStatusNumber(item)"
              :key="index + statusNum"
              :class="`${statusNum.color}--text`"
              ><span class="black--text">{{ index ? '/' : '' }}</span
              >{{ statusNum.status }}</span
            >
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ generateHomeStatus(item).status }}</span>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td
            class="px-0 ps-8"
            :colspan="familyHeaders.length + 1"
            :style="isMobile ? 'width:100vw' : ''"
          >
            <v-data-table
              :headers="familyHeadersExpanded"
              :items="[item]"
              hide-default-footer
              :mobile-breakpoint="100"
              :disable-sort="true"
            >
            </v-data-table>
            <v-data-table
              :headers="individualHeaders"
              :items="extractResponses(item.Residents)"
              hide-default-footer
              hide-default-header
              :mobile-breakpoint="100"
              :item-class="setRowClass"
            >
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <GmapMap
        v-if="!noEvent"
        :center="{
          lat: 31.6603974,
          lng: 35.1768025,
        }"
        :zoom="13"
        style="height: 55vh"
        class="mb-4"
        ref="selectMap"
      >
        <GmapMarker
          v-for="(item, index) in markers"
          :key="item.Location + index"
          :position="{
            lat: parseFloat(item.Location.split(',')[0]),
            lng: parseFloat(item.Location.split(',')[1]),
          }"
          @click="() => toggleMarkerSelection(item.Location)"
          :ref="item.Location"
          :icon="{
            url: item.icon,
            scaledSize: item.scale,
          }"
        />
      </GmapMap>
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utils from '../helpers/avuka.js'
import axios from 'axios'
export default {
  name: 'Avuka-Admin',
  data() {
    return {
      expanded: [],
      noEvent: true,
      chosenMarkers: {},
      search: '',
      filterCity: '',
      filterQuarter: '',
      filterResponseStatus: '',
      loading: false,
      updateEvery: 1,
      state: 1,
      selectedMessage: null,
      messages: [
        'Message 1: Urgent Notice - Weather Advisory',
        'Message 2: Community Meeting Reminder',
        'Message 3: Road Closure Notification',
        'Message 4: Holiday Greetings and Safety Tips',
        'Message 5: Emergency Contact Information Update',
      ],
      items: [],
      familyHeaders: [
        {
          value: 'manualResponse',
          text: '',
          width: '10%',
        },
        {
          value: 'LastName',
          text: 'משפחה',
        },
        {
          text: 'סטטוס דיירים',
          value: 'residentsStatus',
          cellClass: 'residentsStatus',
          class: 'residentsStatus',
        },
        {
          text: 'סטטוס',
          value: 'status',
        },
      ],
      familyHeadersExpanded: [
        {
          value: 'Quarter',
          text: 'אשכול',
        },
        {
          value: 'Street',
          text: 'רחוב',
        },
        {
          value: 'Number',
          text: 'מספר בית',
        },
        {
          value: 'R_ID',
          text: 'מספר נכס',
        },
      ],
      individualHeaders: [
        {
          value: 'FirstName',
          text: 'שם פרטי',
        },
        {
          value: 'LastName',
          text: 'שם משפחה',
        },
        {
          value: 'Phone',
          text: 'טלפון',
        },
        {
          value: 'Comment',
          text: 'הערה',
        },
        {
          value: 'ResponseStatusName',
          text: 'סטטוס',
        },
      ],
      lastUpdate: null,
      timeOut: null,
    }
  },
  methods: {
    manualResponseHandler(item) {
      axios.put(`/event/avuka-my-responses`, {
        eventKey: item.eventKey,
        manualResponse: item.manualResponse,
        familyId: item.Id,
      })
    },
    generateHomeStatusNumber: utils.generateHomeStatusNumber,
    generateHomeStatus: utils.generateHomeStatus,
    extractResponses(items) {
      const responses = utils.extractResponses(items)
      if (!this.filterResponseStatus) return responses
      return responses.filter(
        item => item.ResponseStatusName === this.filterResponseStatus
      )
    },
    setRowHomeClass(item) {
      return this.setHomeRowColor(utils.generateHomeStatus(item))
    },
    setHomeRowColor(item) {
      switch (item.StatusId) {
        case 1:
          return 'lightGreen'
        case 3:
          return 'lightRed'
        case 4:
          return 'white'
      }
    },
    setRowClass(item) {
      switch (item.StatusId) {
        case 0:
          return 'blue'
        case 1:
          return 'green'
        case 2:
          return 'orange'
        case 3:
          return 'red'
      }
    },
    toggleMarkerSelection(Location) {
      const { chosenMarkers } = this
      if (chosenMarkers[Location]) {
        chosenMarkers[Location] = 0
      } else {
        chosenMarkers[Location] = 1
      }
      this.chosenMarkers = { ...chosenMarkers }
    },

    dayjs,
    sendMessage() {
      if (this.selectedMessage) {
        this.state = 2
        this.updateData()
      } else {
        alert('Please select a message to send.')
      }
    },
    async updateData(cancelTimeOut = false) {
      if (cancelTimeOut && this.timeOut) {
        clearTimeout(this.timeOut)
      }
      this.loading = true
      const avukaRes = await utils.avukaData()
      this.loading = false
      if (avukaRes.noEvent) {
        this.noEvent = true
        return
      } else {
        this.items = avukaRes
        if (this.noEvent) this.noEvent = false
      }
      this.lastUpdate = dayjs()
      this.timeOut = setTimeout(() => {
        // this.updateData()
      }, this.updateEvery * 60 * 1000)
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    markers() {
      return utils.getMarkers(this.items, this.chosenMarkers)
    },
    distinctCities() {
      return utils.distinctSelections(this.items, 'City')
    },
    distinctQuarters() {
      return utils.distinctSelections(this.items, 'Quarter')
    },
    distinctResponseStatuses() {
      return utils.distinctResponseStatuses(this.items)
    },
    filteredItems() {
      const filterFunction = utils.generateFilterFunction(
        this.filterCity,
        this.filterQuarter,
        this.filterResponseStatus,
        this.chosenMarkers
      )
      return this.items.filter(filterFunction)
    },
  },
}
</script>
<style scoped>
.avuka-card {
  min-width: 500px;
  min-height: 100px;
}
@media (max-width: 500px) {
  .avuka-card {
    min-width: 100%;
  }
}
::v-deep .residentsStatus {
  max-width: 80px;
}
</style>
